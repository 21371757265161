import React, { FC } from 'react';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import { parseMarkdown } from 'helpers/parseMarkdown';
import CMS from 'utils/cms/strapi';
import trustedByQuery from 'utils/queries/trustedBy';
import reviewsSliderQuery from 'utils/queries/reviewsSlider';
import videosQuery from 'utils/queries/videos';
import { contactFormQuery } from 'utils/queries/contactForm';
import menuQuery from 'utils/queries/menu';
import homesQuery from 'utils/queries/home';
import footerQuery from 'utils/queries/footer';
import { CommonPageProps } from 'utils/types/CommonPageProps';

import { MainSlider, MainSliderProps } from 'components/molecules/MainSlider/MainSlider';
import { CountersSectionProps } from 'components/CountersSection/CountersSection';
import { CoreTechProps } from 'components/CoreTechnologies/CoreTechnologies';
import { FrontendNewsProps } from 'components/FrontendNews/FrontendNews';
import { OurArtsProps } from 'components/OurArts/OurArts';
import { ImageRightSection, ImageRightSectionProps } from 'components/ImageRight/ImageRightSection';
import { ImageLeftSection } from 'components/ImageLeft/ImageLeftSection';
import { TrustedBy, TrustedByProps } from 'components/organisms/TrustedBy/TrustedBy';
import { CoreTeamProps } from 'components/CoreTeam/CoreTeam';
import { ContactFormProps } from 'components/ContactForm/ContactForm';
import Hexagon from 'components/atoms/Geometric/Hexagon/Hexagon';
import { Rhombus } from 'components/atoms/Geometric/Rhombus/Rhombus';
import { MetaTag } from 'components/MetaTags/MetaTags';
import { ReviewsProps } from 'components/molecules/Reviews/Reviews';
import Container from 'components/atoms/Container/Container';
import SliderBoxes, { SliderBoxProps } from 'components/molecules/SliderBoxes/SliderBoxes';
import { VideoTileProps } from 'components/organisms/VideoSection/VideoTile/VideoTile';
import { StyledTopSpacingWrapper } from 'components/ContactForm/ContactForm.styles';
import { getParsedFooterProps } from 'components/Footer/Footer.utils';

const CountersSection = dynamic(() =>
  import('components/CountersSection/CountersSection').then((mod) => mod.CountersSection)
);
const CoreTechnologies = dynamic(() =>
  import('components/CoreTechnologies/CoreTechnologies').then((mod) => mod.CoreTechnologies)
);
const CoreTeam = dynamic(() => import('components/CoreTeam/CoreTeam').then((mod) => mod.CoreTeam));
const Reviews = dynamic(() => import('components/molecules/Reviews/Reviews'));
const OurArts = dynamic(() => import('components/OurArts/OurArts').then((mod) => mod.OurArts));
const ContactForm = dynamic(() => import('components/ContactForm/ContactForm').then((mod) => mod.ContactForm));

type HomesProps = {
  page: {
    metaTags: { meta: Array<MetaTag> };
    mainSlider: MainSliderProps;
    videoButton: MainSliderProps['videoButton'];
    serviceBox: SliderBoxProps['serviceBoxes'];
    supergraphicTop: {
      title: MDXRemoteSerializeResult;
      button: {
        path: string;
        name: string;
      };
      image: {
        src: string;
        alt: string;
      };
    };
    counters: CountersSectionProps;
    coreTeam: CoreTeamProps;
    coreTechnologies: CoreTechProps;
    textAndImage: ImageRightSectionProps;
    ourArts: OurArtsProps;
    frontendNews: FrontendNewsProps;
  };
  trustedBy: TrustedByProps;
  reviewsSlider: ReviewsProps;
  videos: Array<VideoTileProps>;
  contactForm: ContactFormProps;
} & CommonPageProps;

const page = new CMS([
  homesQuery,
  trustedByQuery,
  reviewsSliderQuery,
  () => videosQuery(4),
  contactFormQuery,
  menuQuery,
  footerQuery
]);

export const getStaticProps: GetStaticProps<HomesProps> = async ({ preview = false }) => {
  const [homesResult, trustedByResult, reviewsSliderResult, videosResult, contactFormResult, menuResult, footerResult] =
    await page.getContent();

  const homes = homesResult.data.homes[0];
  const reviewsSlider = reviewsSliderResult.data.reviewsSlider;
  const videos = videosResult.data.videos;

  return {
    props: {
      page: {
        ...homes,
        mainSlider: {
          ...homes.mainSlider,
          title: await parseMarkdown(homes.mainSlider.title),
          description: await parseMarkdown(homes.mainSlider.description)
        },
        supergraphicTop: {
          ...homes.supergraphicTop,
          title: await parseMarkdown(homes.supergraphicTop.title)
        },
        textAndImage: {
          ...homes.textAndImage,
          title: await parseMarkdown(homes.textAndImage.title)
        },
        ourArts: {
          ...homes.ourArts,
          description: await parseMarkdown(homes.ourArts.description)
        },
        coreTeam: {
          ...homes.coreTeam,
          description: await parseMarkdown(homes.coreTeam.description)
        }
      },
      trustedBy: trustedByResult.data.trustedBy,
      reviewsSlider: {
        ...reviewsSlider,
        reviews: await Promise.all(
          reviewsSlider.reviews.map(async ({ author, review, position, ...reviewItem }) => ({
            ...reviewItem,
            author: await parseMarkdown(author),
            position: await parseMarkdown(position),
            review: await parseMarkdown(review)
          }))
        )
      },
      videos,
      contactForm: contactFormResult.data.contactForm,
      menuStructure: menuResult.data.menus,
      footerData: await getParsedFooterProps(footerResult.data.footers[0]),
      isPreview: preview
    }
  };
};

const Home: FC<HomesProps> = ({
  page: {
    mainSlider,
    videoButton,
    serviceBox,
    supergraphicTop,
    counters,
    coreTeam,
    coreTechnologies,
    textAndImage,
    ourArts,
    frontendNews
  },
  trustedBy,
  reviewsSlider,
  videos,
  contactForm
}) => (
  <>
    <MainSlider {...mainSlider} videoButton={videoButton} />
    {serviceBox.length > 0 && <SliderBoxes serviceBoxes={serviceBox} />}
    <ImageLeftSection
      width="150%"
      isRelative={false}
      marginTop="200px"
      {...supergraphicTop}
      imageTop="-64px"
      shapes={
        <>
          <Rhombus width="20px" top="-10px" left="-80px" variant="purple" moveDuration="7s" moveDistance="220px" />
          <Rhombus
            width="13px"
            top="370px"
            left="92%"
            variant="purple"
            moveDuration="5s"
            moveDistance="70px"
            moveDelay="1s"
          />
          <Rhombus
            width="13px"
            top="500px"
            left="8%"
            variant="purple"
            moveDuration="5s"
            moveDistance="150px"
            moveDelay="2s"
          />
        </>
      }
      backShapes={
        <>
          <Hexagon width="604px" top="-30px" left="-182px" variant="whisper" zIndex={0} />
        </>
      }
    />
    <CountersSection {...counters} />
    <CoreTeam {...coreTeam} />
    <TrustedBy {...trustedBy} />
    <Container>
      <Reviews {...reviewsSlider} />
    </Container>
    <CoreTechnologies {...coreTechnologies} {...frontendNews} videos={videos} />
    <ImageRightSection {...textAndImage} />
    <OurArts {...ourArts} />
    <StyledTopSpacingWrapper>
      <ContactForm {...contactForm} />
    </StyledTopSpacingWrapper>
  </>
);

export default Home;
