import React, { FC } from 'react';

import Container from 'components/atoms/Container/Container';
import ServicesBox from './ServicesBox/ServicesBox';

import { StyledSliderBoxesContainer } from './SliderBoxes.styles';

export type SliderBoxProps = {
  serviceBoxes: Array<{
    id: number;
    header: string;
    description: string;
    link: {
      text: string;
      href: string;
    };
  }>;
};

const SliderBoxes: FC<SliderBoxProps> = ({ serviceBoxes }) => (
  <Container>
    <StyledSliderBoxesContainer>
      {serviceBoxes.slice(0, 3).map((item) => (
        <ServicesBox
          key={item.id}
          title={item.header}
          text={item.description}
          href={item.link.href}
          linkText={item.link.text}
        />
      ))}
    </StyledSliderBoxesContainer>
  </Container>
);

export default SliderBoxes;
