import React, { FC } from 'react';
import Image from '@next/image';

import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';

import useMedia from 'hooks/useMedia';
import UploadedImg from 'utils/types/UploadedImg';
import fromCMS from 'utils/fromCMS';

import {
  StyledTrustedByRow,
  StyledClientsSection,
  StyledTitleWrapper,
  StyledClientsGrid,
  StyledContainer
} from './TrustedBy.styles';

export type TrustedByProps = {
  title: string;
  companies: Array<UploadedImg>;
};

export const TrustedBy: FC<TrustedByProps> = ({ title, companies }) => {
  const { isMobile } = useMedia();

  return (
    <StyledContainer>
      <Container>
        <StyledTrustedByRow>
          <StyledClientsSection>
            <StyledTitleWrapper>
              <Typography variant="h1" renderAs="h5">
                {title}
              </Typography>
            </StyledTitleWrapper>
            <StyledClientsGrid>
              {companies.map(({ id, alt, file }) => (
                <Image
                  height={isMobile ? '72px' : '88px'}
                  width={isMobile ? '144px' : '165px'}
                  key={id}
                  src={fromCMS(file.url)}
                  alt={alt}
                  objectFit="contain"
                  quality={100}
                  unoptimized
                />
              ))}
            </StyledClientsGrid>
          </StyledClientsSection>
        </StyledTrustedByRow>
      </Container>
    </StyledContainer>
  );
};
