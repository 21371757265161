import React, { FC } from 'react';
import Image from '@next/image';
import { useInView } from 'react-intersection-observer';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import Hexagon from 'components/atoms/Geometric/Hexagon/Hexagon';
import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';
import { Rhombus } from 'components/atoms/Geometric/Rhombus/Rhombus';
import { Marked } from 'components/Marked/Marked';

import useMedia, { useMinWidthQuery } from 'hooks/useMedia';
import { revealRight } from 'theme/animations';
import Button from 'utils/types/Button';

import {
  StyledContainer,
  StyledTextColumn,
  StyledPhotoColumn,
  StyledAnimatedImageWrapper,
  StyledButtonContainer,
  StyledTextWrapper,
  StyledWrapper
} from './ImageRightSection.styles';

export type ImageRightSectionProps = {
  title: MDXRemoteSerializeResult;
  button: Button;
  image: {
    src: string;
    alt: string;
  };
};

export const ImageRightSection: FC<ImageRightSectionProps> = ({ title, button, image }) => {
  const { isMobile } = useMedia();
  const isDesktop = useMinWidthQuery('xsLarge');
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

  return (
    <StyledWrapper>
      <Container>
        <StyledContainer>
          {!isMobile && <Hexagon width="700px" top="-416px" left="-371px" variant="whisper" />}
          <StyledTextColumn>
            <StyledTextWrapper>
              {!isMobile && (
                <>
                  <Rhombus width="13px" top="80px" left="-35%" variant="purple" moveDistance="50px" moveDuration="4s" />
                  <Rhombus
                    width="25px"
                    bottom="-140px"
                    left="-50px"
                    variant="purple"
                    moveDistance="120px"
                    moveDuration="6s"
                    moveDelay="1s"
                  />
                </>
              )}
              <Typography variant="h2" weight="300">
                <Marked>{title}</Marked>
              </Typography>
              <StyledButtonContainer>
                <LinkedButton href={button.href}>{button.buttonCopy}</LinkedButton>
              </StyledButtonContainer>
            </StyledTextWrapper>
          </StyledTextColumn>
          <StyledPhotoColumn ref={ref}>
            <div>
              {isDesktop && (
                <>
                  <Rhombus
                    width="16px"
                    top="16%"
                    left="15%"
                    variant="purple"
                    moveDistance="100px"
                    moveDuration="5s"
                    moveDelay="0.5s"
                  />
                  <Rhombus
                    width="9px"
                    top="580px"
                    left="-60px"
                    variant="purple"
                    moveDistance="200px"
                    moveDuration="9s"
                    moveDelay="1.2s"
                  />
                  <Rhombus width="9px" top="50%" left="85%" variant="purple" moveDistance="40px" moveDuration="3s" />
                  <StyledAnimatedImageWrapper animate={inView ? 'visible' : 'hidden'} variants={revealRight}>
                    <div style={{ width: 682, height: 766, position: 'relative' }}>
                      <Image
                        layout="fill"
                        src={`${image.src}.png`}
                        alt={image.alt}
                        objectFit="scale-down"
                        quality={100}
                      />
                    </div>
                  </StyledAnimatedImageWrapper>
                </>
              )}
            </div>
          </StyledPhotoColumn>
        </StyledContainer>
      </Container>
    </StyledWrapper>
  );
};
