import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledSliderBoxesContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-around;
  margin-top: ${({ theme }) => theme.spacing(-11)};
  row-gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  z-index: 1;

  ${mq.medium`
    grid-template-columns: repeat(3, minmax(0,1fr));
    grid-column-gap: ${({ theme }) => theme.spacing(2)};
  `}

  ${mq.large`
    justify-content: flex-start;
    margin: ${({ theme }) => theme.spacing(-4, 'auto', 0)};
  `}

  ${mq.xsLarge`
    max-width: 752px;
    margin: ${({ theme }) => theme.spacing(-8, 0, 0, 'auto')};
    padding: ${({ theme }) => theme.spacing(0, 4, 0, 0)};
  `}
`;
