import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { mq } from 'theme/media';
import { ImageLeftSectionProps } from './ImageLeftSection';

type BoxProps = {
  reverseOrder: boolean;
};

export const StyledWrapper = styled(motion.div)<{
  marginTop?: ImageLeftSectionProps['marginTop'];
  paddingBottom?: ImageLeftSectionProps['paddingBottom'];
}>`
  position: relative;
  display: grid;

  ${mq.large`
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${({ theme }) => theme.spacing(9)};
    margin-top: ${({ marginTop }) => marginTop};
    padding-bottom: ${({ paddingBottom }) => paddingBottom};
    margin-top: ${({ theme }) => theme.spacing(12)}
  `}

  ${mq.xsLarge`
    margin-top: ${({ theme }) => theme.spacing(16)}
  `}
`;

export const StyledTextBox = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  z-index: 2;
  justify-content: center;

  ${mq.large`
    width: 80%;
    order: ${({ reverseOrder }) => (reverseOrder ? 0 : 1)};
  `}
`;

export const StyledTextWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(9, 0, 5)};
`;

export const StyledImageBox = styled.div<BoxProps>`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 400px;
  margin: auto;

  ${mq.large`
    max-width: none;
    width: auto;
    margin: 0;
    order: ${({ reverseOrder }) => (reverseOrder ? 1 : 0)};
  `}
`;

export const StyledAnimatedImageWrapper = styled(motion.div)<{
  width?: ImageLeftSectionProps['width'];
  isRelative?: ImageLeftSectionProps['isRelative'];
  imageTop?: ImageLeftSectionProps['imageTop'];
}>`
  z-index: 1;

  ${mq.large`
    top: ${({ imageTop }) => imageTop};
    width: ${({ width }) => (width ? width : '100%')};
    position: ${({ isRelative }) => (isRelative ? 'relative' : 'absolute')};
    right: 0;
  `}
`;

export const StyledHoverBox = styled.div`
  display: none;

  ${mq.large`
    display: flex;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    align-items: center;
  `}
`;

export const StyledImageWrapper = styled.div`
  position: relative;
`;

export const StyledBackShapes = styled.div`
  display: none;

  ${mq.large`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `}
`;

export const StyledButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledContainer = styled.div<{ overflowHidden: boolean }>`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(9)};

  ${({ overflowHidden }) =>
    overflowHidden &&
    css`
      overflow: hidden;
    `}

  ${mq.large`
    margin-bottom: 0;
  `}
`;
