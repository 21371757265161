import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledTrustedByRow = styled.div`
  flex-direction: column;
  display: flex;

  ${mq.large`
    flex-direction: row;
  `}
`;

export const StyledClientsSection = styled.div`
  flex: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const StyledClientsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  grid-row-gap: ${({ theme }) => theme.spacing(3)};
  z-index: -1;

  ${mq.large`
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: ${({ theme }) => theme.spacing(4)};
  `}
`;

export const StyledLearnMoreContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(6)};
  position: relative;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  ${mq.large`
    margin-top: 0;
  `}
`;

export const StyledLearnMore = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const StyledContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(9, 0)};
  overflow: hidden;

  ${mq.xsLarge`
    padding: ${({ theme }) => theme.spacing(18, 0, 16)};
  `}
`;
