import styled from 'styled-components';
import { motion } from 'framer-motion';

import { mq } from 'theme/media';

export const StyledContainer = styled.div`
  position: relative;

  ${mq.large`
    display: flex;
    margin: 0 auto;
  `}
`;

export const StyledTextColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  ${mq.xsLarge`
    padding-bottom: ${({ theme }) => theme.spacing(15)};
    margin-left: ${({ theme }) => theme.spacing(15)};
  `}
`;

export const StyledButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(5)};

  ${mq.large`
    width: 100%;
  `}
`;

export const StyledPhotoColumn = styled(motion.div)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  ${mq.xsLarge`
    margin-right: ${({ theme }) => theme.spacing(-18)};
  `}
`;

export const StyledAnimatedImageWrapper = styled(motion.div)`
  width: 100%;
`;

export const StyledTextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mq.xsLarge`
    margin: ${({ theme }) => theme.spacing(5, 0, 0, 6)};
  `}
`;

export const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${({ theme }) => theme.spacing(9)};

  ${mq.xsLarge`
    padding-top: ${({ theme }) => theme.spacing(1)};
  `}
`;
