import styled from 'styled-components';

export const StyledServicesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.whisper};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  z-index: 2;
`;

export const StyledTextWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(3, 4, 2)};
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledDescriptionWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  flex: 1;
`;
