import React, { FC, ReactNode } from 'react';
import Image from '@next/image';
import { useInView } from 'react-intersection-observer';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';

import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';
import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import { Marked } from 'components/Marked/Marked';

import { revealLeft, revealRight } from 'theme/animations';

import useMedia from 'hooks/useMedia';

import {
  StyledWrapper,
  StyledTextBox,
  StyledTextWrapper,
  StyledImageBox,
  StyledImageWrapper,
  StyledAnimatedImageWrapper,
  StyledHoverBox,
  StyledBackShapes,
  StyledContainer
} from './ImageLeftSection.styles';

export type ImageLeftSectionProps = {
  title: MDXRemoteSerializeResult;
  image: {
    src: string;
    alt: string;
  };
  button: { name: string; path: string };
  shapes?: ReactNode;
  backShapes?: ReactNode;
  reverseOrder?: boolean;
  hideImageOnMobile?: boolean;
  width?: string;
  isRelative?: boolean;
  marginTop?: string;
  paddingBottom?: string;
  imageTop?: string;
  overflowHidden?: boolean;
  threshold?: number;
};

export const ImageLeftSection: FC<ImageLeftSectionProps> = ({
  title,
  image,
  button,
  shapes,
  backShapes,
  reverseOrder = false,
  hideImageOnMobile = false,
  width = '100%',
  isRelative = true,
  marginTop = '72px',
  imageTop = '0',
  overflowHidden,
  paddingBottom = '0',
  threshold = 0.5
}) => {
  const { isMobile } = useMedia();
  const shouldHideImage = (isMobile && hideImageOnMobile) || !isMobile;

  const { ref, inView } = useInView({ triggerOnce: true, threshold: threshold });

  return (
    <StyledContainer ref={ref} overflowHidden={overflowHidden}>
      <Container>
        <StyledWrapper marginTop={marginTop} paddingBottom={paddingBottom}>
          {shouldHideImage && (
            <StyledImageBox reverseOrder={reverseOrder}>
              <StyledImageWrapper>
                <StyledAnimatedImageWrapper
                  imageTop={imageTop}
                  animate={inView ? 'visible' : 'hidden'}
                  variants={reverseOrder ? revealRight : revealLeft}
                  width={width}
                  isRelative={isRelative}
                >
                  <Image
                    src={image.src}
                    alt={image.alt}
                    width={750}
                    height={567}
                    objectFit="scale-down"
                    quality={100}
                    unoptimized
                  />
                </StyledAnimatedImageWrapper>
                {shapes && <StyledHoverBox>{shapes}</StyledHoverBox>}
              </StyledImageWrapper>
            </StyledImageBox>
          )}
          <StyledTextBox reverseOrder={reverseOrder}>
            <StyledTextWrapper>
              <Typography variant="h2" weight="300">
                <Marked>{title}</Marked>
              </Typography>
            </StyledTextWrapper>
            <LinkedButton href={button.path}>{button.name}</LinkedButton>
          </StyledTextBox>
          {backShapes && <StyledBackShapes>{backShapes}</StyledBackShapes>}
        </StyledWrapper>
      </Container>
    </StyledContainer>
  );
};
